import React, { useState, useEffect, useForceUpdate } from "react"
import firebase from "gatsby-plugin-firebase"
import Header from "../components/blogHeader"
import _ from "lodash"
import Title from "../components/title"
import Footer from "../components/footer"
import moment from "moment"
import { getDates } from "../db/helpers"

// Styles
import styles from "../styles/components/admin-reservations.module.scss"

const AdminReservations = () => {
  const [data, setData] = useState()
  const [dates, setDates] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const [filteredData, setFilteredData] = useState()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    firebase
      .database()
      .ref()
      .on("value", snapshot => {
        const data = snapshot.val()
        let dateArr = []
        _.forEach(data, (val, key) => {
          dateArr.push(key)
        })
        const sortedArr = dateArr.sort((a, b) => {
          // Sort by date
          const aVal = moment(a, "DD-MM-YYYY").unix()
          const bVal = moment(b, "DD-MM-YYYY").unix()
          return bVal - aVal
        })
        setData(_.cloneDeep(data))
        setDates(_.cloneDeep(sortedArr))
      })
  }

  const filterData = date => {
    if (!date || date === "-") {
      return
    }
    const reservationsForSelectedDate = {}
    _.forEach(data[date], (reservationArr, selectedDate) => {
      let reservations = []
      _.forEach(reservationArr, (val, key) => {
        reservations.push(val)
      })
      reservationsForSelectedDate[selectedDate] = reservations
    })
    setFilteredData(reservationsForSelectedDate)
  }

  const sendDateThroughSelect = e => {
    selectDate(e.target.value)
  }

  const selectDate = date => {
    if (date) {
      setSelectedDate(date)
      filterData(date)
    }
  }

  return (
    <>
      <Header />
      <div className={styles.main}>
        <div className={styles.title}>
          <Title text="Reservations Admin Panel" />
        </div>
        <div className={styles.content}>
          <div className={styles.formData}>
            <div className={styles.selectDate}>
              <label htmlFor="date">
                Please Select Date:
                <select
                  className={styles.select}
                  id="dateSelect"
                  onChange={sendDateThroughSelect}
                  onBlur={sendDateThroughSelect}
                >
                  <option>-</option>
                  {dates &&
                    dates.map((date, idx) => (
                      <option value={date} key={idx}>
                        {date}
                      </option>
                    ))}
                </select>
              </label>
            </div>

            <div className={styles.reservations}>
              {filteredData &&
                Object.entries(filteredData).map(([key, value]) => {
                  return (
                    <div key={key}>
                      <h4 className={styles.date}>{key}:</h4>

                      {value.map((name, idx) => (
                        <p key={idx} className={styles.name}>
                          <span>{idx + 1}.</span> {name}
                        </p>
                      ))}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AdminReservations
