const moment = require("moment")

const getTimeSlots = date => {
  if (moment(date, "DD-MM-YYYY").format("dddd") === "Sunday") {
    return ["8:00AM - 10:00AM", "10:00AM - 12:00PM", "12:00PM - 2:00PM"]
  } else if (moment(date, "DD-MM-YYYY").format("dddd") === "Saturday") {
    return [
      "6:00AM - 8:00AM",
      "8:00AM - 10:00AM",
      "10:00AM - 12:00PM",
      "12:00PM - 2:00PM",
      "2:00PM - 4:00PM",
      "4:00PM - 6:00PM",
      "6:00PM - 8:00PM",
      "8:00PM - 10:00PM",
    ]
  } else {
    return [
      "4:00AM - 6:00AM",
      "6:00AM - 8:00AM",
      "8:00AM - 10:00AM",
      "10:00AM - 12:00PM",
      "12:00PM - 1:00PM",
      "1:00PM - 3:00PM",
      "3:00PM - 5:00PM",
      "5:00PM - 8:00PM",
      "8:00PM - 10:00PM",
      "10:00PM - 12:00AM",
    ]
  }
}

const getDates = () => {
  const today = moment()
    .format("DD-MM-YYYY")
    .toString()

  const tomorrow = moment()
    .add(1, "days")
    .format("DD-MM-YYYY")
    .toString()

  return [today, tomorrow]
}

const getTomorrowDate = () =>
  moment()
    .add(1, "days")
    .format("DD-MM-YYYY")
    .toString()

module.exports = {
  getTomorrowDate,
  getDates,
  getTimeSlots,
}
